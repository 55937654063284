<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import "../assets/index.scss";
import {
  companyArticlesApi,
  partyArticlesApi,
  industryArticlesApi,
} from "../api/content";
import router from "@/router";

const dynamicData = ref("");
const activityData = ref("");
const industryDynamics = ref("");
function getCompanyArticlesList() {
  companyArticlesApi().then((res) => {
    const formattedData = res.data.data.map((item: any) => {
      const coverUrl =
        item.attributes.cover.data == null
          ? ""
          : "https://pusi.mychery.com:5443" +
            item.attributes.cover.data.attributes.url;
      const title = item.attributes.title;
      const text = item.attributes.content;
      const id = item.id;

      return {
        id: id,
        img: coverUrl,
        title: title,
        text: text,
      };
    });
    dynamicData.value = formattedData;
  });
}
function getPartyArticlesList() {
  partyArticlesApi().then((res) => {
    console.log(res);
    const formattedData = res.data.data.map((item: any) => {
      console.log(item.attributes.cover.data);
      const coverUrl =
        item.attributes.cover.data == null
          ? ""
          : "https://pusi.mychery.com:5443" +
            item.attributes.cover.data.attributes.url;
      console.log(coverUrl);
      const title = item.attributes.title;
      const text = item.attributes.content;
      const id = item.id;
      return {
        id: id,
        img: coverUrl,
        title: title,
        text: text,
      };
    });
    activityData.value = formattedData;
  });
}
function getIndustryArticlesList() {
  industryArticlesApi().then((res) => {
    const formattedData = res.data.data.map((item: any) => {
      const coverUrl =
        item.attributes.cover.data == null
          ? ""
          : "https://pusi.mychery.com:5443" +
            item.attributes.cover.data.attributes.url;
      const text = item.attributes.title;
      const date = new Date(item.attributes.createdAt);
      const beijingDate = new Date(date.getTime() + 8 * 60 * 60 * 1000);
      const day = beijingDate.getDay();
      const time = beijingDate.getFullYear() + "/" + beijingDate.getMonth();
      const id = item.id;
      return {
        id: id,
        img: coverUrl,
        text: text,
        time: time,
        day: day,
      };
    });
    industryDynamics.value = formattedData;
  });
}
getCompanyArticlesList();
getPartyArticlesList();
getIndustryArticlesList();

const activeIndex = ref("1");
const menuItems = [
  "首页",
  "关于普思",
  "业务介绍",
  "公司新闻",
  "行业动态",
  // "服务项目",
  "党建活动",
  "数字化管理平台",
  "登录",
];

const items = [
  {
    img: require("../assets/home0.png"),
    hoverImg: require("../assets/home-bg1.png"),
    title: "国内认证",
    subtitle: "Domestic Certification",
    text: "国内认证服务主要涵盖汽车整车的工信部产品准入认证、工信部车船税，减免税认证、生态环境部环保认证、认监委CCC认证、北京生态局环保认证、交通部营运安全达标认证等行业主管部门的强制性法规认证服务。",
  },
  {
    img: require("../assets/home1.png"),
    hoverImg: require("../assets/home-bg2.png"),
    title: "国际认证",
    subtitle: "International Certification",
    text: "国际认证服务主要涵盖汽车整车及零部件的欧盟E-mark认证、海湾GCC认证、澳大利亚ADR认证、东南亚相关国家认证等出口认证服务。",
  },
  {
    img: require("../assets/home2.png"),
    hoverImg: require("../assets/home-bg3.png"),
    title: "试验管理",
    subtitle: "Road Test",
    text: "试验管理部主要负责公司检测机构管理，认证试验管理、检测费用管理、安全管理、车辆管理、报告管理等，为认证工作开展做保障。",
  },
  {
    img: require("../assets/home3.png"),
    hoverImg: require("../assets/home-bg4.png"),
    title: "创新发展",
    subtitle: "Innovation Development",
    text: "负责公司企业资质准入管理及咨询服务工作，负责公司创新发展项目开拓，政府奖补项目申报管理，负责公司运营管理，行政管理，负责对外合作项目，负责合同、档案管理、日常费用管理等职能。",
  },

  {
    img: require("../assets/home4.png"),
    hoverImg: require("../assets/home-bg5.png"),
    title: "法规库",
    subtitle: "Regulations database",
    text: "国内、国际标准法规库平台，收录并更新最新认证标准法规用于查询、整合、研究、分析功能，同时打通标准法规与认证系统证书、数据、报告库，实时应对法规变动时产生的影响。",
  },
  {
    img: require("../assets/home5.png"),
    hoverImg: require("../assets/home-bg6.png"),
    title: "参数收集",
    subtitle: "Parameter collection",
    text: "延伸认证材料收集功能至各专业端，并进行整合、通过精确划分参数归属部门、保持后端参数名称统一对参数进行收集，线上化对收集的参数、图片进行审核、提高审核效率。",
  },
  {
    img: require("../assets/home6.png"),
    hoverImg: require("../assets/home-bg7.png"),
    title: "道路实验室",
    subtitle: "Digital road lab",
    text: "主要承接公司场地试验开展，按欧标及国标开展整车道路性能测试、加速噪声测试、胎压监测、转向力测试、滑行阻力测试、制动试验等道路检测试验。",
  },
];
const activeIndexHome = ref(0);
const setActiveIndex = (index: number) => {
  activeIndexHome.value = index;
};
const computedTitle = computed(() => {
  switch (activeIndexHome.value) {
    case 0:
      return items[0].title;
    case 1:
      return items[1].title;
    case 2:
      return items[2].title;
    case 3:
      return items[3].title;
    case 4:
      return items[4].title;
    case 5:
      return items[5].title;
    case 6:
      return items[6].title;
    default:
      return "title";
  }
});

const computedText = computed(() => {
  switch (activeIndexHome.value) {
    case 0:
      return items[0].text;
    case 1:
      return items[1].text;
    case 2:
      return items[2].text;
    case 3:
      return items[3].text;
    case 4:
      return items[4].text;
    case 5:
      return items[5].text;
    case 6:
      return items[6].text;
    default:
      return "text";
  }
});
const handleItemClick = (index: number) => {
  if (carousel.value) {
    carousel.value.setActiveItem(index - 1);
  }
  activeIndex.value = String(index);
  if (index === 8) {
    denglu();
  }
};

const headerHeight = 76; // 固定的 header 高度

const dynamicCarouselHeight = computed(() => {
  // 计算 el-carousel 的高度，当前屏幕高度减去 header 的高度
  const screenHeight = window.innerHeight;
  return `${screenHeight - headerHeight}px`;
});
const carousel = ref(null);
const animationTrigger = ref(false);
console.log(animationTrigger.value, "2");
onMounted(() => {
  if (animationTrigger.value) {
    console.log(animationTrigger.value);
    animationTrigger.value = !animationTrigger.value;
  }
});
function rollScroll(event: string) {
  if (carousel.value) {
    // 根据滚动方向调用相应的方法
    if (event.deltaY > 0) {
      carousel.value.next(); // 滚轮向下滚动，调用next方法切换到下一页
    } else if (event.deltaY < 0) {
      carousel.value.prev(); // 滚轮向上滚动，调用prev方法切换到上一页
    }
  }
}

function navigateToRegulations() {
  // 使用 window.location.href 进行页面跳转
  let indexLink = "http://psbz.tjxsinfo.com:8002/bankuai02.html";
  window.open(indexLink, "_blank");
}

function neibu() {
  // 使用 window.location.href 进行页面跳转
  let indexLink = "http://psbz.tjxsinfo.com:8002/";
  window.open(indexLink, "_blank");
}

function denglu() {
  let indexLink = "http://psbz.tjxsinfo.com:8001/oauth/login?login_type=1";
  window.open(indexLink, "_self");
}

function waibu() {
  // 使用 window.location.href 进行页面跳转
  window.location.href = "https://pusi.mychery.com/oauth/kh-login?login_type=2";
}

//跳转公司动态列表
function toCompanyList() {
  let routeData = router.resolve({
    path: "/list",
    query: {
      category: "company",
    },
  });
  window.open(routeData.href, "_blank");
}
function toPartyList() {
  let routeData = router.resolve({
    path: "/list",
    query: {
      category: "party",
    },
  });
  window.open(routeData.href, "_blank");
}
function toIndustryList() {
  let routeData = router.resolve({
    path: "/list",
    query: {
      category: "industry",
    },
  });
  window.open(routeData.href, "_blank");
}

function handleListClick(category: any, id: any) {
  let routeData = router.resolve({
    path: "/content",
    query: {
      id: id,
      category: category,
    },
  });
  window.open(routeData.href, "_blank");
}
</script>

<template>
  <div class="home-center">
    <el-row class="header">
      <img class="logo" src="../assets/Logo.png@2x.png" alt="普思标准" />
      <ul class="header-right">
        <li
          v-for="(item, index) in menuItems"
          :key="index"
          :class="{ selected: activeIndex === String(index + 1) }"
          @click="handleItemClick(index + 1)"
        >
          {{ item }}
        </li>
        <!-- <li @click="navigateToRegulations">法规库</li> -->
      </ul>
    </el-row>
    <div class="header-section">
      <div>
        <el-carousel
          :height="dynamicCarouselHeight"
          direction="vertical"
          :autoplay="false"
          @mousewheel="rollScroll($event)"
          ref="carousel"
          :loop="false"
          :setActiveItem="activeIndex"
        >
          <el-carousel-item v-for="item in 8" :key="item">
            <template v-if="item === 3">
              <div class="layout-1">
                <div class="item animated animate__backInUp">
                  <div>
                    <p class="item-title">{{ computedTitle }}</p>
                    <p class="item-text">{{ computedText }}</p>
                    <el-button type="info" round style="opacity: 0">
                      了解更多
                      <img src="../assets/learnMore.png" alt="了解更多" />
                    </el-button>
                  </div>
                  <div class="item-bottom">
                    <div
                      v-for="(item, index) in items"
                      :key="index"
                      class="item-bottom-box"
                      :class="{ active: index === activeIndexHome }"
                      @mouseover="setActiveIndex(index)"
                    >
                      <img class="bottom-box-img" :src="item.img" alt="" />
                      <img
                        class="bottom-box-img-hover"
                        :src="item.hoverImg"
                        alt=""
                      />
                      <span>{{ item.title }}</span>
                      <span class="box-es">{{ item.subtitle }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item === 2">
              <div class="layout-1 aboutUs">
                <div
                  ref="animationTrigger"
                  class="item animated animate__slideInLeft"
                >
                  <p class="aboutUs-title" style="color: black">关于普思</p>
                  <p class="aboutUs-title-es" style="color: black">
                    About Pusi
                  </p>
                  <p
                    class="aboutUs-section"
                    style="color: black; line-height: 1.5"
                  >
                    安徽普思标准技术有限公司成立于2016年，是一家专业提供汽车整车及零部件国内外法规认证、试验测试、系统技术集成开发与测试验证、专业技术咨询等服务的第三方机构。公司注册资本为500万元人民币，总部位于中国(安徽)自由贸易试验区芜湖片区。自2016年以来，公司与国家管理部门及国内外认证机构建立了常态化的认证管理协调机制，与50多家汽车企业合作，拥有丰富的行业经验。
                  </p>
                  <p
                    class="aboutUs-section section-bottom animated animate__slideInLeft"
                  ></p>
                  <!-- <el-button type="info" round>
                    了解更多
                    <img src="../assets/learnMore.png" alt="了解更多" />
                  </el-button> -->
                </div>
              </div>
            </template>
            <template v-if="item === 1">
              <div class="dl_bg">
                <video
                  src="../assets/03.mp4"
                  autoplay="true"
                  loop="true"
                  muted="true"
                  style="width: 100%; height: 100%; object-fit: fill"
                  width="100%"
                  class="video"
                ></video>
              </div>
              <div class="dl_img"><img src="../assets/10.png" /></div>
            </template>
            <template v-if="item === 4">
              <div class="layout-1 dynamic">
                <div class="item">
                  <div class="dynamic-top">
                    <div>
                      <p class="aboutUs-title">公司新闻</p>
                      <p class="aboutUs-title-es">Company News</p>
                    </div>
                    <div>
                      <el-button type="info" round v-on:click="toCompanyList">
                        了解更多
                        <img src="../assets/learnMore.png" alt="了解更多" />
                      </el-button>
                    </div>
                  </div>
                  <div>
                    <el-carousel
                      :interval="4000"
                      type="card"
                      height="511px"
                      arrow="always"
                      indicator-position="none"
                      :autoplay="false"
                    >
                      <el-carousel-item
                        v-for="dynamicItem in dynamicData"
                        :key="dynamicItem"
                        v-on:click="handleListClick('company', dynamicItem.id)"
                      >
                        <!--                      <h3 text="2xl" justify="center">{{ item }}</h3>-->
                        <div class="dynamic-box">
                          <img :src="dynamicItem.img" alt="" />
                          <p class="top" style="font-size: 20px">
                            {{ dynamicItem.title }}
                          </p>
                          <p class="bottom" style="font-size: 14px">
                            {{ dynamicItem.text }}
                          </p>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item === 7">
              <div class="layout-1 system">
                <div class="item">
                  <p class="aboutUs-title">普思数字化管理平台</p>
                  <p class="aboutUs-title-es">Pusi System</p>
                  <p class="system-section">
                    是多模块的业务管理软件系统，全面支持汽车整车认证、国际认证、道路试验和创新发展等领域的多方面服务。这一综合解决方案包括国内认证项目管理系统、国际认证项目管理系统、道路实验管理系统以及创新发展管理系统四大关键模块。
                  </p>
                  <p class="system-section system-bottom">
                    通过这套系统，能够高效管理和确保实施国内和国际认证标准，规划并执行道路试验并生成相关报告。客户可发起新的认证项目与检测试验并查阅认证项目进度，上传信息文件，下载报告与证书。
                  </p>
                  <div class="system-right-box">
                    <img
                      class="box1"
                      src="../assets/system-bg-right.png"
                      alt=""
                    />
                    <img
                      class="box2"
                      src="../assets/system-bg-left.png"
                      alt=""
                    />
                  </div>
                  <div style="display: flex">
                    <el-button type="info" round @click="neibu">
                      更多
                      <img src="../assets/learnMore.png" alt="了解更多" />
                    </el-button>
                    <!-- <el-button type="info" round @click="waibu">
                      外部客户登录
                      <img src="../assets/learnMore.png" alt="了解更多" />
                    </el-button> -->
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item === 5">
              <div class="layout-1 industry-dynamics">
                <div class="item">
                  <div class="dynamic-top">
                    <div>
                      <p class="aboutUs-title">行业动态</p>
                      <p class="aboutUs-title-es">Pusi System</p>
                    </div>
                    <div>
                      <el-button type="info" round v-on:click="toIndustryList">
                        了解更多
                        <img src="../assets/learnMore-bg.png" alt="了解更多" />
                      </el-button>
                    </div>
                  </div>
                  <ul class="dynamic-bottom">
                    <li
                      v-for="(
                        industryDynamicsItem, industryDynamicsIndex
                      ) in industryDynamics"
                      :key="industryDynamicsIndex"
                      v-on:click="
                        handleListClick('industry', industryDynamicsItem.id)
                      "
                    >
                      <div class="box">
                        <div class="left">
                          <img :src="industryDynamicsItem.img" alt="" />
                        </div>
                        <div class="right">
                          <div class="time">
                            <p class="time-top">
                              {{ industryDynamicsItem.day }}
                            </p>
                            <p class="time-bottom">
                              {{ industryDynamicsItem.time }}
                            </p>
                          </div>
                          <div class="text">
                            {{ industryDynamicsItem.text }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item === 6">
              <!-- <div class="layout-1 activity">
                <div class="item">
                  <div class="dynamic-top">
                    <div>
                      <p class="aboutUs-title">党建活动</p>
                      <p class="aboutUs-title-es">Party Building Activities</p>
                    </div>
                    <div>
                      <el-button type="info" round v-on:click="toPartyList">
                        了解更多
                        <img src="../assets/learnMore-bg.png" alt="了解更多" />
                      </el-button>
                    </div>
                  </div>
                  <div class="layout-1 dynamic" style="background-image: none">
                    <div class="item" style="width: 100%">
                      <div>
                        <el-carousel
                          :interval="4000"
                          type="card"
                          height="511px"
                          arrow="always"
                          indicator-position="none"
                          :autoplay="false"
                        >
                          <el-carousel-item
                            v-for="dynamicItem in activityData"
                            :key="dynamicItem"
                            v-on:click="
                              handleListClick('party', dynamicItem.id)
                            "
                          >
                            <div class="dynamic-box">
                              <img :src="dynamicItem.img" alt="" />
                              <p class="top" style="font-size: 20px">
                                {{ dynamicItem.title }}
                              </p>
                              <p class="bottom" style="font-size: 14px">
                                {{ dynamicItem.text }}
                              </p>
                            </div>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="layout-1 dynamic activitynew">
                <div class="item">
                  <div class="dynamic-top">
                    <div>
                      <p class="aboutUs-title forceblack">党建活动</p>
                      <p class="aboutUs-title-es forceblack">
                        Party Building Activities
                      </p>
                    </div>
                    <div>
                      <el-button
                        type="info"
                        round
                        v-on:click="toPartyList"
                        class="forceblack buttonforceblack"
                      >
                        了解更多
                        <img src="../assets/learnMore-bg.png" alt="了解更多" />
                      </el-button>
                    </div>
                  </div>
                  <div>
                    <el-carousel
                      :interval="4000"
                      type="card"
                      height="511px"
                      arrow="always"
                      indicator-position="none"
                      :autoplay="false"
                    >
                      <el-carousel-item
                        v-for="dynamicItem in activityData"
                        :key="dynamicItem"
                        v-on:click="handleListClick('company', dynamicItem.id)"
                      >
                        <!--                      <h3 text="2xl" justify="center">{{ item }}</h3>-->
                        <div class="dynamic-box">
                          <img :src="dynamicItem.img" alt="" />
                          <p class="top" style="font-size: 20px">
                            {{ dynamicItem.title }}
                          </p>
                          <p class="bottom" style="font-size: 14px">
                            {{ dynamicItem.text }}
                          </p>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item === 8">
              <div class="layout-1 other">
                <div class="other-bottom">
                  <div class="box">
                    <div class="other-bottom-box">
                      <p class="title">合规举报渠道</p>
                      <p class="text">合规电话</p>
                      <p class="text">0553-7526147</p>
                      <p class="text">合规邮箱</p>
                      <p class="text">hegui@mychery.com</p>
                    </div>
                    <!-- <div class="other-bottom-box">
                      <p class="title">我们的服务</p>
                      <p class="text">国内认证</p>
                      <p class="text">国际认证</p>
                      <p class="text">道路试验</p>
                      <p class="text">创新发展</p>
                    </div> -->
                    <div class="other-bottom-box">
                      <p class="title">普思数字化管理平台</p>
                      <p class="text">产品认证管理系统</p>
                      <p class="text">标准法规库</p>
                      <p class="text">数字化道路实验室</p>
                      <p class="text">业财一体化平台</p>
                      <p class="text">数据集成系统</p>
                      <!-- <p class="text">系统一</p>
                      <p class="text">系统一</p> -->
                    </div>
                    <div class="other-bottom-box">
                      <p class="title">联系我们</p>
                      <p class="text my-box">
                        公司地址：中国(安徽)自由贸易试验区芜湖片区银湖北路38号留学生楼D501室
                      </p>
                      <p class="text my-box">
                        公司电话：0553-5636736 工作日09:00-18:00
                      </p>
                      <p class="text my-box">邮编：241006</p>
                      <p class="text">&nbsp;</p>
                    </div>
                    <!-- <div class="other-bottom-box wx">
                      <p class="text">&nbsp;</p>
                      <div class="text text-box"></div>
                      <p class="text text-box-bottom">资质证书</p>
                    </div> -->
                  </div>
                </div>
                <div class="footer">
                  <div>©2023 普思标准所有</div>
                  <div class="box"></div>
                  <div>京ICP备5683325568号</div>
                </div>
              </div>
            </template>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(
    .el-carousel
      .el-carousel__indicators--right
      .el-carousel__indicator--vertical
      button
  ) {
  width: 16px;
  height: 16px;
  opacity: 0.4;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  margin-bottom: 16px;
}

:deep(.el-carousel .el-carousel__indicators--right .is-active button) {
  background: rgba(0, 140, 214, 1);
  border: none;
  border-radius: 50%;
  opacity: 0.5;
}
.dynamic {
  .item {
    .el-carousel__item .el-carousel__mask {
      background: transparent !important;
    }
    :deep(.el-carousel__container .el-carousel__item .el-carousel__mask) {
      background: #fff !important;
      border-radius: 32px;
      opacity: 0;
    }
    :deep(.el-carousel .el-carousel__container .el-carousel__arrow) {
      width: 70px;
      height: 70px;
      background-color: rgba(0, 0, 0, 1);
    }
    :deep(.el-carousel .el-carousel__container .el-carousel__arrow .el-icon) {
      font-size: 28px;
      color: #fff;
    }
    :deep(.el-carousel__container .is-active) {
      background-color: rgba(0, 140, 214, 1) !important;
      border-radius: 32px;
    }

    :deep(.el-carousel__container .is-active .dynamic-box) {
      .top {
        color: rgba(255, 255, 255, 1) !important;
        padding: 0 12px;
      }
      .bottom {
        color: rgba(255, 255, 255, 1) !important;
        padding: 0 12px;
      }
    }
    :deep(.el-carousel__container .is-in-stage) {
      background-color: #fff;
      border-radius: 32px;
    }
    .el-carousel__item .dynamic-box {
      height: 100%;
      border-radius: 32px;
      img {
        width: 100%;
        height: 360px;
      }
      .top {
        /** 文本1 */
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 34.75px;
        color: rgba(0, 0, 0, 1);
        padding: 0 12px;
        margin: 17px 0 0 0;
      }
      .bottom {
        /** 文本1 */
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(153, 153, 153, 1);
        padding: 0 12px;
      }
    }
  }
}
</style>
