import axios from "axios";
import qs from "qs";
import { ElMessage } from "element-plus";
/**
 * axios的传参方式：
 * 1.url 传参 一般用于Get和Delete 实现方式：config.params={JSON}
 * 2.body传参 实现方式：config.data = {JSON}，且请求头为：headers: { 'Content-Type': 'application/json;charset=UTF-8' }
 * 3.表单传参 实现方式：config.data = qs.stringify({JSON})，且请求头为：且请求头为：headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
 */
// axios实例
const service = axios.create({
  //   baseURL: "http://192.168.0.90:1337/",
  // baseURL: "http://tjxsservice.tpddns.cn:1337/",
  baseURL: "https://pusi.mychery.com:5443/",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization:
      "Bearer 1c09082002b7fd2cef1a88ff5b627af0a28608e6dbee4e15c93660595575c6d78f2deb5576d8da76836ae5209420772a7060dcf194e2c44aaf0ba00898f164e39f8abcd6f7c50a2796828e0935aabb283f4e554cddcecdceb4471160d8a733dc10c74a4ccd81421938232d424c8d86f3dac47b875ffe27fad9603fa3a51900ca",
    // "Bearer c388962a655f2fd245a5ca0ddc70ad8849e7d9cd81582569a0132cc0c97dccf3dbfd7daffc7cb5aba70c1b3e9b50577f4d928627e5fefcef1a8f6f49f279a5a15d6ef2c3b21ae116d1b77dc6cffb2508dcfb479a3f745bb420dcf6a991b912edf3b838784bf0ed3e4a2414b55b4edf88f47853710ba87cb532349596a0a50941",
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 追加时间戳，防止GET请求缓存
    if (config.method?.toUpperCase() === "GET") {
      config.params = { ...config.params, t: new Date().getTime() };
    }

    if (
      Object.values(config.headers).includes(
        "application/x-www-form-urlencoded"
      )
    ) {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 导出 axios 实例
export default service;
