<script setup lang="ts">
import { defineComponent, ref, reactive, onMounted } from "vue";
defineComponent({ name: "HomePage" });

const routerData = ref([
  { id: 1, title: "首页", path: "", children: [] },
  { id: 2, title: "关于普思", path: "#about", children: [] },
  {
    id: 3,
    title: "业务介绍",
    path: "#business",
    children: [
      { pid: "3", id: "1", title: "法规库", path: "", children: [] },
      { pid: "3", id: "2", title: "参数收集", path: "", children: [] },
      { pid: "3", id: "3", title: "国内认证", path: "", children: [] },
      {
        pid: "3",
        id: "4",
        title: "国际认证",
        path: "",
        children: [
          { pid: "3-4", id: "1", title: "欧洲", path: "" },
          { pid: "3-4", id: "2", title: "巴西", path: "" },
          { pid: "3-4", id: "3", title: "台湾", path: "" },
        ],
      },
      { pid: "3", id: "5", title: "试验管理", path: "", children: [] },
      { pid: "3", id: "6", title: "创新发展", path: "", children: [] },
    ],
  },
  { id: 4, title: "公司新闻", path: "#news", children: [] },
  { id: 5, title: "行业动态", path: "#industryDynamics", children: [] },
  { id: 6, title: "党建活动", path: "#partyBuildingActivities", children: [] },
  {
    id: 7,
    title: "数字化管理平台",
    path: "#digitalManagmentPlatform",
    children: [],
  },
  {
    id: 8,
    title: "登录",
    path: "",
    children: [
      { pid: "8", id: "1", title: "内部登录", path: "", children: [] },
      { pid: "8", id: "2", title: "客户登录", path: "", children: [] },
      { pid: "8", id: "3", title: "合作伙伴", path: "", children: [] },
    ],
  },
]);
const activeIndex = ref("1");
const handleSelect = (key: string, keyPath: string[]) => {
  const obj = routerData.value.find(
    (item: any) =>
      Number(item.id) ===
      (keyPath.length === 1 ? Number(key) : Number(keyPath[0])),
  );
  if (obj) {
    activeIndex.value = String(obj.id);
    // 跳锚链接 其他自行处理
    document.querySelector(obj.path)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};
const handleClick = (options: any) => {
  console.log(options);
  activeIndex.value = String(options.id);
  if (options.path) {
    // 跳锚链接 其他自行处理
    document.querySelector(options.path)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};
const bannerList = ref([
  {
    id: 1,
    img: require("../../assets/images/banner.png"),
    video: require("../../assets/03.mp4"),
    type: "video",
  },
  {
    id: 2,
    img: require("../../assets/images/banner.png"),
    video: require("../../assets/03.mp4"),
    type: "img",
  },
  {
    id: 3,
    img: require("../../assets/images/banner.png"),
    video: require("../../assets/03.mp4"),
    type: "img",
  },
]);
const about = reactive({
  title: "关于普思",
  tips: "关于普思关于普思关于普思关于普思关于普思关于普思关于普思",
  content:
    "安徽普思标准技术有限公司成立于2016年1月，地址位于中国(安徽)自由贸易试验区芜湖片区银湖北路38号。公司主营汽车国内、国际产品认证与检测检验、汽车咨询，生产资质准入、产品研发等业务。公司现有员工48人，其中试验管理部14人，国内认证部12人，国际认证部13人，创新发展部7人，是有一支专业、年轻、有梦想的团队。公司秉承开拓创新、专业高效、顾客满意、合作共赢经营理念，致力于以国际视野在全球化背景下整合国内外法规、检测与认证资源，建立新型、专业化法规研究，检测与认证服务平台，为中国制造走向国际市场提供专业、高效的一站式技术服务。",
  type: [
    { id: 1, title: "开拓创新" },
    { id: 2, title: "专业高效" },
    { id: 3, title: "顾客满意" },
    { id: 4, title: "合作共赢" },
  ],
});
const businessList = ref([
  {
    id: 1,
    title: "法规库",
    content: "简介简介简介简介简介简介简介简介简介简介简介简介简介",
    img: require("../../assets/images/bu1.png"),
  },
  {
    id: 2,
    title: "参数收集",
    content: "简介简介简介简介简介简介简介简介简介简介简介简介简介",
    img: require("../../assets/images/bu2.png"),
  },
  {
    id: 3,
    title: "国内认证",
    content: "简介简介简介简介简介简介简介简介简介简介简介简介简介",
    img: require("../../assets/images/bu3.png"),
  },
  {
    id: 4,
    title: "国际认证",
    content: "简介简介简介简介简介简介简介简介简介简介简介简介简介",
    img: require("../../assets/images/bu4.png"),
  },
  {
    id: 5,
    title: "法规库",
    content: "简介简介简介简介简介简介简介简介简介简介简介简介简介",
    img: require("../../assets/images/bu5.png"),
  },
  {
    id: 6,
    title: "参数收集",
    content: "简介简介简介简介简介简介简介简介简介简介简介简介简介",
    img: require("../../assets/images/bu6.png"),
  },
  {
    id: 7,
    title: "国内认证",
    content: "简介简介简介简介简介简介简介简介简介简介简介简介简介",
    img: require("../../assets/images/bu7.png"),
  },
]);
const businessCurrent = ref(1);
const businessToggle = (id: number) => (businessCurrent.value = id);
const newsList = ref([
  {
    id: 1,
    title: "标题标题标题标题标题标题标题",
    content:
      "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介介绍介绍介介绍介绍介",
    cover: require("../../assets/images/new1.png"),
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 2,
    title: "标题标题标题标题标题标题标题",
    content:
      "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介介绍介绍介介绍介绍介",
    cover: require("../../assets/images/new1.png"),
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 3,
    title: "标题标题标题标题标题标题标题",
    content:
      "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介介绍介绍介介绍介绍介",
    cover: require("../../assets/images/new1.png"),
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 4,
    title: "标题标题标题标题标题标题标题",
    content:
      "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介介绍介绍介介绍介绍介",
    cover: require("../../assets/images/new1.png"),
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
]);
const newsLeft = reactive({
  id: 1,
  title: "标题标题标题标题标题标题标题",
  content:
    "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介介绍介绍介介绍介绍介",
  cover: require("../../assets/images/new1.png"),
  createdAt: {
    date: "2023-01-24",
    day: 24,
    month: "01",
    year: "2023",
  },
});
const industryDynamicsList = ref([
  {
    id: 1,
    title:
      "传“XXX”完成3.5亿美元e轮融资，估值超过10亿美元，ceo已发内部邮件确认。",
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 2,
    title: "从硅谷火到中国特斯拉发布全新半挂卡车Semi。",
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 3,
    title: "爆料：XXX平台无故封号，用户投诉无门",
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 4,
    title: "【工作技巧】产品经理如何处理工作中的突发事件",
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
]);
const partyBuildingActivitesList = ref([
  {
    id: 1,
    title:
      "标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题",
    cover: require("../../assets/images/dj1.png"),
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 2,
    title:
      "标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题",
    cover: require("../../assets/images/dj2.png"),
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
  {
    id: 3,
    title:
      "标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题",
    cover: require("../../assets/images/dj3.png"),
    createdAt: {
      date: "2023-01-24",
      day: 24,
      month: "01",
      year: "2023",
    },
  },
]);
const digitalManagmentPlatformList = ref([
  {
    id: 1,
    title: "产品认证管理系统",
    content: "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍",
    img: require("../../assets/images/pt1.png"),
  },
  {
    id: 2,
    title: "标准法规库",
    content: "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍",
    img: require("../../assets/images/pt2.png"),
  },
  {
    id: 3,
    title: "数字化道路实验",
    content: "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍",
    img: require("../../assets/images/pt3.png"),
  },
  {
    id: 4,
    title: "业财一体化平台",
    content: "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍",
    img: require("../../assets/images/pt4.png"),
  },
  {
    id: 5,
    title: "数据集成系统",
    content: "介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍",
    img: require("../../assets/images/pt5.png"),
  },
]);
const wow = new WOW({
  boxClass: "wow",
  animateClass: "animated",
  offset: 0,
  mobile: true,
  live: true,
});
wow.init();
onMounted(() => {
  const aboutBottom = document.querySelector("#aboutInt_bottom");
  const aboutLeft = document.querySelector(
    ".aboutInt_left .aboutInt_left_header",
  );
  const aboutRight = document.querySelector(".aboutInt_right");
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        console.log(entry.isIntersecting);
        if (entry.isIntersecting) {
          aboutLeft && aboutLeft.classList.add("animate__fadeInUp");
          aboutRight && aboutRight.classList.add("animate__fadeInUp");
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0.1, // Adjust this value as needed
    },
  );
  if (aboutBottom) {
    observer.observe(aboutBottom);
  }
});
</script>
<template>
  <div class="home">
    <div class="container">
      <div class="navbar">
        <div class="navbar_logo">
          <img src="../../assets/images/Logo.png" alt="" />
        </div>
        <el-menu
          ellipsis
          :default-active="activeIndex"
          class="navbar_list"
          mode="horizontal"
          @select="handleSelect"
          background-color="rgba(0, 140, 214, 1)"
          text-color="rgba(235, 235, 235, 1)"
          active-text-color="rgba(235, 235, 235, 1)"
        >
          <template v-for="item in routerData" :key="item.id">
            <template v-if="item.children.length === 0">
              <el-menu-item :index="String(item.id)">{{
                item.title
              }}</el-menu-item>
            </template>
            <template v-else-if="item.children.length > 0">
              <el-sub-menu
                :index="String(item.id)"
                popper-class="navbar_popper navbar_popper_list"
                @click.native="handleClick(item)"
                :class="{ 'is-active': activeIndex === String(item.id) }"
              >
                <template #title>
                  <span>{{ item.title }}</span>
                  <span class="icon">
                    <img src="../../assets/images/a_b.png" alt="" />
                  </span>
                </template>
                <template v-for="child in item.children" :key="child.id">
                  <template v-if="child.children.length === 0">
                    <el-menu-item
                      :index="String(child.pid) + '-' + String(child.id)"
                      >{{ child.title }}</el-menu-item
                    >
                  </template>
                  <template v-else-if="child.children.length > 0">
                    <el-sub-menu
                      :index="String(child.pid) + '-' + String(child.id)"
                    >
                      <template #title>{{ child.title }}</template>
                      <template v-if="child.children">
                        <template v-for="sub in child.children" :key="sub.id">
                          <el-menu-item
                            :index="String(sub.pid) + '-' + String(sub.id)"
                            >{{ sub.title }}</el-menu-item
                          >
                        </template>
                      </template>
                      <template v-else-if="sub.children.length > 0">
                        <el-sub-menu
                          :index="String(sub.pid) + '-' + String(sub.id)"
                        >
                          <template #title>{{ sub.title }}</template>
                          <template v-if="sub.children">
                            <template
                              v-for="subs in sub.children"
                              :key="subs.id"
                            >
                              <el-menu-item
                                :index="
                                  String(subs.pid) + '-' + String(subs.id)
                                "
                                >{{ subs.title }}</el-menu-item
                              >
                            </template>
                          </template>
                        </el-sub-menu>
                      </template>
                    </el-sub-menu>
                  </template>
                </template>
              </el-sub-menu>
            </template>
          </template>
          <!-- <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">关于普思</el-menu-item>
          <el-sub-menu
            index="3"
            popper-class="navbar_popper navbar_popper_list"
          >
            <template #title>
              <span>业务介绍</span>
              <span class="icon">
                <img src="../../assets/images/a_b.png" alt="" />
              </span>
            </template>
            <el-menu-item index="3-1">法规库</el-menu-item>
            <el-menu-item index="3-2">参数收集</el-menu-item>
            <el-menu-item index="3-3">国内认证</el-menu-item>
            <el-sub-menu index="3-4">
              <template #title>国际认证</template>
              <el-menu-item index="3-4-1">欧洲</el-menu-item>
              <el-menu-item index="3-4-2">巴西</el-menu-item>
              <el-menu-item index="3-4-3">台湾</el-menu-item>
            </el-sub-menu>
            <el-menu-item index="3-5">试验管理</el-menu-item>
            <el-menu-item index="3-6">创新发展</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="4">公司新闻</el-menu-item>
          <el-menu-item index="5">行业动态</el-menu-item>
          <el-menu-item index="6">党建活动</el-menu-item>
          <el-menu-item index="7">数字化管理平台</el-menu-item>
          <el-sub-menu
            index="8"
            popper-class="navbar_popper navbar_popper_login"
          >
            <template #title>
              <span>登录</span>
              <span class="icon">
                <img src="../../assets/images/a_b.png" alt="" />
              </span>
            </template>
            <el-menu-item index="8-1">内部登录</el-menu-item>
            <el-menu-item index="8-2">客户登录</el-menu-item>
            <el-menu-item index="8-3">合作伙伴</el-menu-item>
          </el-sub-menu> -->
        </el-menu>
      </div>
    </div>
    <div class="banner">
      <video
        src="../../assets/03.mp4"
        autoplay="true"
        loop="true"
        muted="true"
        style="width: 100%; height: 100%; object-fit: fill"
        width="100%"
        class="video"
      ></video>
      <img
        class="cover"
        src="https://pusi.mychery.com:7443/img/10.d1dc8485.png"
        alt=""
      />
      <!--<el-carousel
        trigger="click"
        height="700px"
        arrow="never"
        :autoplay="true"
      >
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img v-if="item.type === 'img'" :src="item.img" alt="" />
          <video
            v-else-if="item.type === 'video'"
            :src="item.video"
            autoplay="true"
            loop="true"
            muted="true"
            style="width: 100%; height: 100%; object-fit: fill"
            width="100%"
            class="video"
          ></video>
        </el-carousel-item>
      </el-carousel>-->
    </div>
    <div id="about" class="aboutInt">
      <div class="aboutInt_left">
        <div class="aboutInt_left_header wow animated">
          <p class="trans">ABOUT PUSI STANDARD</p>
          <p class="h1">{{ about.title }}</p>
          <p class="des">{{ about.tips }}</p>
          <p class="content">
            {{ about.content }}
          </p>
        </div>
      </div>
      <div class="aboutInt_right wow animated">
        <p class="" v-for="item in about.type" :key="item.id">
          {{ item.title }}
        </p>
      </div>
      <div id="aboutInt_bottom" style="position: absolute; bottom: 25%"></div>
    </div>
    <div id="business" class="business">
      <div class="business_header base_header">
        <p class="trans">BUSINESS INTRODUCTION</p>
        <p class="h1">业务介绍</p>
      </div>
      <div class="business_container">
        <div class="container">
          <div
            class="item"
            v-for="item in businessList"
            :key="item.id"
            @click="businessToggle(item.id)"
          >
            <div class="box">
              <div class="cover">
                <img :src="item.img" alt="" />
              </div>
              <p class="title">{{ item.title }}</p>
              <p class="content">{{ item.content }}</p>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="news" class="news">
      <div class="news_header base_header">
        <p class="trans">PUSI STANDARD NEWS</p>
        <p class="h1">普思新闻</p>
      </div>
      <div class="news_container">
        <div class="container">
          <div class="left">
            <div class="box">
              <div class="cover">
                <img :src="newsLeft.cover" alt="" />
              </div>
              <div class="text">
                <p class="time">
                  <span class="day">{{ newsLeft.createdAt.day }}</span>
                  /{{ newsLeft.createdAt.year }}-{{ newsLeft.createdAt.month }}
                </p>
                <p class="title">{{ newsLeft.title }}</p>
                <p class="content">{{ newsLeft.content }}</p>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="item" v-for="item in newsList" :key="item.id">
              <div class="box">
                <div class="time">
                  <p class="day">{{ item.createdAt.day }}</p>
                  <p class="ym">
                    {{ newsLeft.createdAt.year }}-{{ item.createdAt.month }}
                  </p>
                </div>
                <div class="text">
                  <p class="title">{{ item.title }}</p>
                  <p class="content">{{ item.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnGroup seeMore">
        <button>
          查看更多<el-icon><ArrowRight /></el-icon>
        </button>
      </div>
    </div>
    <div id="industryDynamics" class="industryDynamics">
      <div class="industryDynamics_header base_header">
        <p class="trans">INDUSTRY DYNAMICS</p>
        <p class="h1">行业动态</p>
      </div>
      <div class="industryDynamics_container">
        <div class="container">
          <div
            class="item animated animate__fadeInRight"
            v-for="item in industryDynamicsList"
            :key="item.id"
          >
            <div class="time">
              <p class="day">{{ item.createdAt.day }}</p>
              <p class="ym">
                {{ item.createdAt.year }}/{{ item.createdAt.month }}
              </p>
            </div>
            <p class="title">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="btnGroup seeMore">
        <button>
          查看更多<el-icon><ArrowRight /></el-icon>
        </button>
      </div>
    </div>
    <div id="partyBuildingActivities" class="partyBuildingActivities">
      <div class="partyBuildingActivities_header base_header">
        <p class="trans">PARTY BUILDING ACTIVITIES</p>
        <p class="h1">党建活动</p>
      </div>
      <div class="partyBuildingActivities_container">
        <div class="container">
          <el-carousel
            type="card"
            height="459px"
            arrow="never"
            :interval="3000"
            :autoplay="true"
          >
            <el-carousel-item
              v-for="item in partyBuildingActivitesList"
              :key="item.id"
            >
              <img class="cover" :src="item.cover" alt="" />
              <div class="box">
                <div class="time">
                  <span class="day">{{ item.createdAt.day }}</span>
                  <span class="ym"
                    >/{{ item.createdAt.year }}-{{ item.createdAt.month }}</span
                  >
                </div>
                <div class="arrow">
                  <img src="../../assets/images/a_r.png" alt="" />
                </div>
              </div>
              <div class="title">{{ item.title }}</div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="btnGroup seeMore">
        <button>
          查看更多<el-icon><ArrowRight /></el-icon>
        </button>
      </div>
    </div>
    <div id="digitalManagmentPlatform" class="digitalManagmentPlatform">
      <div class="digitalManagmentPlatform_header base_header">
        <p class="trans">DIGITAL MANAGEMENT PLATFORM</p>
        <p class="h1">数字化管理平台</p>
      </div>
      <div class="digitalManagmentPlatform_container">
        <div class="container">
          <div
            class="item"
            v-for="item in digitalManagmentPlatformList"
            :key="item.id"
          >
            <img :src="item.img" alt="" class="cover" />
            <div class="box">
              <div class="title">{{ item.title }}</div>
              <div class="content">{{ item.content }}</div>
              <div class="btn">访问{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-1 other">
      <div class="other-bottom">
        <div class="box">
          <div class="other-bottom-box">
            <p class="title">合规举报渠道</p>
            <p class="text">合规电话</p>
            <p class="text">0553-7526147</p>
            <p class="text">合规邮箱</p>
            <p class="text">hegui@mychery.com</p>
          </div>
          <div class="other-bottom-box">
            <p class="title">普思数字化管理平台</p>
            <p class="text">产品认证管理系统</p>
            <p class="text">标准法规库</p>
            <p class="text">数字化道路实验室</p>
            <p class="text">业财一体化平台</p>
            <p class="text">数据集成系统</p>
          </div>
          <div class="other-bottom-box">
            <p class="title">联系我们</p>
            <p class="text my-box">
              公司地址：中国(安徽)自由贸易试验区芜湖片区银湖北路38号留学生楼D501室
            </p>
            <p class="text my-box">公司电话：0553-5636736 工作日09:00-18:00</p>
            <p class="text my-box">邮编：241006</p>
            <p class="text">&nbsp;</p>
          </div>
        </div>
      </div>
      <div class="footer">
        <div>©2023 普思标准所有</div>
        <div class="box"></div>
        <div>京ICP备5683325568号</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("./header.min.css");
@mixin text-overflow($line-clamp: 1) {
  @if $line-clamp == 1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } @else {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line-clamp;
    overflow: hidden;
  }
}

@mixin bg-img($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left top;
}

.container {
  width: 1400px;
  height: auto;
}
.home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  $transition-time: 0.3s;
  .container {
    position: relative;
  }

  .base_header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .trans {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 28.96px;
      color: rgba(153, 153, 153, 1);
      text-align: center;
      vertical-align: top;
    }
    .h1 {
      margin-top: 2px;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 52.13px;
      color: rgba(0, 0, 0, 1);
      text-align: center;
      vertical-align: top;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after,
      &::before {
        content: "";
        width: 28px;
        height: 0px;
        border: 1px solid rgba(0, 0, 0, 1);
      }
      &::after {
        margin-left: 12px;
      }
      &::before {
        margin-right: 12px;
      }
    }
  }
  .seeMore {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 160px;
      height: 48px;
      border-radius: 24px;
      border: 1px solid rgba(0, 140, 214, 1);
      background-color: #fff;
      // background: rgba(0, 140, 214, 1);
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 23.17px;
      color: rgba(0, 140, 214, 1);
      // color: rgba(255, 255, 255, 1);
      text-align: center;
      vertical-align: top;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-color: rgba(0, 140, 214, 1);
        background: rgba(0, 140, 214, 1);
        color: rgba(255, 255, 255, 1);
      }
      .el-icon {
        margin-left: 14px;
      }

      img {
        width: 14px;
        height: 14px;
        margin-left: 14px;
      }
    }
  }

  :deep(.banner) {
    width: 100%;
    height: 100vh;
    position: relative;
    .cover {
      width: 853px;
      height: 361px;
      position: absolute;
      top: calc(50% - 180.5px);
      left: calc(50% - 426.5px);
    }
    .el-carousel {
      &__container {
        width: 100%;
        height: 100%;
        position: relative;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   background-color: rgba(0, 0, 0, 0.4);
        // }
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__indicators {
        width: 280px;
        .el-carousel__indicator {
          width: 58px;
          height: 8px;
          padding: 0 6px 20px 6px;

          .el-carousel__button {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .aboutInt {
    width: 100%;
    height: 800px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include bg-img("../../assets/images/image.png@3x.png");
    &_left {
      width: 702px;
      // margin-top: 204px;
      margin-right: 178px;
      .trans {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 28.96px;
        color: rgba(153, 153, 153, 1);
        text-align: left;
        vertical-align: top;
      }
      .h1 {
        margin-top: 4px;
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
      }
      .des {
        margin-top: 16px;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(102, 102, 102, 1);
        text-align: left;
        vertical-align: top;
      }
      .content {
        margin-top: 60px;
        text-indent: 2em;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 30px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
      }
    }
    &_right {
      width: 400px;
      height: 198px;
      // margin-top: 301px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      p {
        height: 40px;
        text-wrap: nowrap;
        font-size: 44px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 63.71px;
        color: rgba(40, 64, 90, 1);
        text-align: left;
        vertical-align: top;
        &:nth-child(n + 3) {
          margin-top: 70px;
        }
        &:nth-child(odd) {
          margin-right: 48px;
        }
      }
    }
  }

  .business {
    width: 100%;
    &_header {
      margin-top: 80px;
    }
    &_container {
      margin-top: 60px;
      margin-bottom: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .item {
          width: 330px;
          // min-width: calc(25% - 26px);
          height: 328px;
          overflow: hidden;
          margin: 0 13px;
          margin-bottom: 40px;
          position: relative;
          cursor: pointer;
          transition: all $transition-time;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(4n + 1) {
            margin-left: 0;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            transform: scaleY(0);
            transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
            transform-origin: top;
            transition-delay: 0s !important;
          }
          &:hover {
            &::after {
              z-index: 1;
              transform-origin: bottom;
              transform: scaleY(1);
              box-shadow: 0px 8px 19px #dedede;
              background-color: rgba(0, 140, 214, 1);
            }
            transform: translateY(-10px);
            .box {
              // .cover {
              //   img {
              //     transform: scale(1.1);
              //   }
              // }
              .title,
              .content {
                // color: rgba(0, 140, 214, 1);
                color: rgba(255, 255, 255, 1);
              }
              .line {
                background: linear-gradient(
                  90deg,
                  rgba(27, 112, 253, 1) 0%,
                  rgba(0, 140, 214, 1) 100%
                );
              }
            }

            border-bottom-color: linear-gradient(
              90deg,
              rgba(27, 112, 253, 1) 0%,
              rgba(0, 140, 214, 1) 100%
            );
          }
          .box {
            position: absolute;
            z-index: 2;
            .cover {
              width: 100%;
              height: 220px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all $transition-time;
              }
            }
            .title {
              padding: 0 10px;
              margin-top: 16px;
              margin-bottom: 8px;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0px;
              line-height: 26px;
              color: rgba(0, 0, 0, 1);
              text-align: left;
              vertical-align: top;
              @include text-overflow;
              transition: all $transition-time;
            }
            .content {
              padding: 0 10px;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 20px;
              color: rgba(102, 102, 102, 1);
              text-align: left;
              vertical-align: top;
              @include text-overflow(2);
              transition: all $transition-time;
            }
            .line {
              margin-top: 16px;
              width: 100%;
              height: 2px;
              background: rgba(204, 204, 204, 1);
              transition: all $transition-time;
            }
          }
        }
      }
    }
  }

  .news {
    width: 100%;
    height: 880px;
    @include bg-img("../../assets/images/news.png");
    &_header {
      margin-top: 100px;
    }

    &_container {
      display: flex;
      justify-content: center;
      .container {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;

        .left {
          width: 660px;
          height: 488px;
          margin-right: 39px;
          position: relative;
          background: rgba(255, 255, 255, 1);
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            transform: scaleY(0);
            transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
            transform-origin: top;
            transition-delay: 0s !important;
          }
          &:hover {
            &::after {
              z-index: 1;
              transform-origin: bottom;
              transform: scaleY(1);
              box-shadow: 0px 8px 19px #dedede;
              background-color: rgba(0, 140, 214, 1);
            }
            .box {
              .cover img {
                transform: scale(1.1);
              }
              .text {
                .time,
                .time .day,
                .title,
                .content {
                  color: #fff;
                }
              }
            }
          }
          .box {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;

            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

            .cover {
              width: 100%;
              height: 341px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all $transition-time;
              }
            }
            .text {
              margin: 16px 20px;
              .time {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20.27px;
                color: rgba(153, 153, 153, 1);
                text-align: left;
                vertical-align: top;
                transition: all $transition-time;
                .day {
                  font-size: 24px;
                  line-height: 34.75px;
                  color: rgba(0, 0, 0, 1);
                  transition: all $transition-time;
                }
              }
              .title {
                margin-top: 8px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 26.06px;
                color: rgba(0, 0, 0, 1);
                text-align: left;
                vertical-align: top;
                transition: all $transition-time;
                @include text-overflow;
              }
              .content {
                margin-top: 8px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20.27px;
                color: rgba(102, 102, 102, 1);
                text-align: left;
                vertical-align: top;
                transition: all $transition-time;
                @include text-overflow(2);
              }
            }
          }
        }
        .right {
          width: 700px;
          height: 100%;
          .item {
            width: 700px;
            height: 110px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
            position: relative;
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 0px;
              left: 0;
              z-index: 0;
              width: 100%;
              height: 100%;
              transform: scaleY(0);
              transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
              transform-origin: top;
              transition-delay: 0s !important;
            }
            &:hover {
              &::after {
                z-index: 1;
                transform-origin: bottom;
                transform: scaleY(1);
                box-shadow: 0px 8px 19px #dedede;
                background-color: rgba(0, 140, 214, 1);
              }
              .box {
                .time .day,
                .time .ym {
                  color: #fff;
                }
                .text {
                  .title,
                  .content {
                    color: #fff;
                  }
                }
              }
            }
            &:not(:last-child) {
              margin-bottom: 16px;
            }

            .box {
              position: absolute;
              z-index: 2;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              .time {
                width: 56px;
                padding: 0 20px;
                border-right: 1px solid rgba(235, 235, 235, 1);
                .day {
                  margin-bottom: 6px;
                  font-size: 24px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  line-height: 34.75px;
                  color: rgba(0, 0, 0, 1);
                  text-align: right;
                  vertical-align: top;
                  transition: all $transition-time;
                }
                .ym {
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  line-height: 20.27px;
                  color: rgba(153, 153, 153, 1);
                  text-align: left;
                  vertical-align: top;
                  transition: all $transition-time;
                }
              }
              .text {
                width: 0;
                flex: 1;
                padding: 0 20px;
                .title {
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  line-height: 23.17px;
                  color: rgba(0, 0, 0, 1);
                  text-align: left;
                  vertical-align: top;
                  transition: all $transition-time;
                  @include text-overflow;
                }
                .content {
                  margin-top: 8px;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  line-height: 20.27px;
                  color: rgba(102, 102, 102, 1);
                  text-align: left;
                  vertical-align: top;
                  transition: all $transition-time;
                  @include text-overflow;
                }
              }
            }
          }
        }
      }
    }
    .btnGroup {
      margin-top: 40px;
    }
  }
  .industryDynamics {
    width: 100%;
    height: 828px;
    @include bg-img("../../assets/images/news.png");
    &_header {
      margin-top: 80px;
    }
    &_container {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      .container {
        .item {
          display: flex;
          align-items: center;
          transition: all 0.5s;
          &:not(:last-child) {
            margin-bottom: 44px;
          }
          &:hover {
            margin-left: 20px;
          }
          .time {
            .day {
              font-size: 30px;
              font-weight: 500;
              letter-spacing: 0px;
              line-height: 43.44px;
              color: rgba(0, 0, 0, 1);
              text-align: left;
              vertical-align: top;
            }
            .ym {
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 0px;
              line-height: 28.96px;
              color: rgba(0, 0, 0, 1);
              text-align: left;
              vertical-align: top;
            }
          }
          .title {
            margin-left: 32px;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 34.75px;
            color: rgba(0, 0, 0, 1);
            text-align: left;
            vertical-align: top;
          }
        }
      }
    }
    .btnGroup {
      margin-top: 72px;
    }
  }
  .partyBuildingActivities {
    width: 100%;
    height: 831px;
    @include bg-img("../../assets/images/djhd.png");
    &_header {
      margin-top: 80px;
    }
    &_container {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      .container {
        :deep(.el-carousel) {
          .el-carousel__item {
            border-radius: 30px;
            overflow: hidden;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
            &.is-in-stage {
              // width: 538px;
              // height: 398px;
              .cover {
                height: 374px;
              }
            }
            &.is-active {
              width: 620px;
              height: 459px;
              .cover {
                height: 359px;
              }
            }
            .cover {
              width: 100%;
              object-fit: cover;
            }
            .box {
              margin-top: 12px;
              padding: 0 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .time {
                width: 0;
                flex: 1;
                .day {
                  font-size: 24px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  line-height: 34.75px;
                  color: rgba(0, 0, 0, 1);
                  text-align: left;
                  vertical-align: center;
                }
                .ym {
                  margin-left: 4px;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  line-height: 20.27px;
                  color: rgba(153, 153, 153, 1);
                  vertical-align: center;
                }
              }
              .arrow {
                width: 36px;
                height: 36px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .title {
              margin-top: 6px;
              padding: 0 20px;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0px;
              line-height: 26.06px;
              color: rgba(0, 0, 0, 1);
              text-align: left;
              vertical-align: top;
              @include text-overflow;
            }
          }
        }
      }
    }
    .btnGroup {
      margin-top: 40px;
    }
  }
  .digitalManagmentPlatform {
    margin-bottom: 110px;
    &_header {
      margin-top: 110px;
    }
    &_container {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      .container {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 260px;
          height: 346px;
          border-radius: 10px;
          overflow: hidden;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
          &:hover .box {
            //.title,.content {color: rgba(0, 140, 214, 1);}
            .btn {
              background: rgba(0, 140, 214, 1);
              border: 1px solid rgba(0, 140, 214, 1);
              color: rgba(255, 255, 255, 1);
            }
          }

          &:not(&:nth-child(n + 5)) {
            margin-right: 25px;
          }
          .cover {
            width: 100%;
            height: 200px;
          }
          .box {
            width: calc(100% - 20px);
            padding: 12px 10px 20px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
              width: 100%;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0px;
              line-height: 23.17px;
              text-align: left;
              vertical-align: top;
              color: rgba(0, 0, 0, 1);
              @include text-overflow;
            }
            .content {
              margin-top: 5px;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 20.27px;
              text-align: left;
              vertical-align: top;
              color: rgba(102, 102, 102, 1);
              @include text-overflow(2);
            }
            .btn {
              margin-top: 12px;
              width: 170px;
              height: 32px;
              padding: 0 10px;
              border-radius: 1px;
              border: 1px solid rgba(0, 140, 214, 1);
              font-size: 14px;
              font-weight: 400;
              line-height: 32px;
              color: rgba(0, 140, 214, 1);
              text-align: center;
              vertical-align: top;

              @include text-overflow;
            }
          }
        }
      }
    }
  }
  .other {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: end;
    align-items: center;
    .other-item {
      width: 100%;
      height: 180px;
      background-image: url("../../assets/activity-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      .other-box {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-box {
          width: 48%;
          margin: 0 auto 94px;
          img {
            width: 100%;
            height: 184px;
          }
          .section {
            margin: 30px 0 20px;
            /** 文本1 */
            font-size: 36px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 52.13px;
            color: rgba(0, 0, 0, 1);
          }
          .bottom {
            /** 文本1 */
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 26.06px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
    }
    .other-bottom {
      width: 100%;
      height: 480px;
      background: rgba(51, 51, 51, 1);
      .box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 1400px;
        max-width: 1400px;
        margin: 0 auto;
        padding-top: 112px;
        .other-bottom-box {
          .title {
            /** 文本1 */
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 34.75px;
            color: rgba(255, 255, 255, 1);
            margin-bottom: 40px;
          }
          .text {
            /** 文本1 */
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 28.96px;
            color: rgba(179, 179, 179, 1);
            margin-bottom: 8px;
          }
          .my-box {
            /** 文本1 */
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 28.96px;
            color: rgba(255, 255, 255, 1);
          }
          .text-box {
            width: 148px;
            height: 148px;
            opacity: 1;
            background: rgba(204, 204, 204, 1);
            padding-top: 20px;
          }
          .text-box-bottom {
            /** 文本1 */
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20.27px;
            color: rgba(179, 179, 179, 1);
          }
        }
        .wx {
          position: relative;
          margin-top: 40px;
        }
      }
    }
    .footer {
      height: 112px;
      width: 100%;
      min-width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(51, 51, 51, 1);
      border-top: 1px solid #474747;
      div {
        width: auto;
        opacity: 1;
        /** 文本1 */
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 112px;
        color: rgba(102, 102, 102, 1);
        text-align: left;
      }
      .box {
        width: 1px;
        height: 20px;
        opacity: 1;
        background: rgba(204, 204, 204, 1);
        margin: 0 12px;
      }
    }
  }
}
</style>
