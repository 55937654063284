<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import "../assets/index.scss";
import { useRoute, useRouter } from "vue-router";
import router from "@/router";
import {
  companyArticlesListApi,
  partyArticlesListApi,
  industryArticlesListApi,
} from "../api/content";

const dynamicData = ref("");
const totalPage = ref("");
const nowPage = ref("");
const route = useRoute();
const category = route.query.category;
const categoryText = ref("");

function handleCurrentChange(val: any) {
  nowPage.value = val;
  if (category == "party") {
    partyListApi(val);
    categoryText.value = "党建活动";
  } else if (category == "company") {
    getCompanyArticlesList(val);
    categoryText.value = "公司动态";
  } else if (category == "industry") {
    getIndustryArticlesList(val);
    categoryText.value = "行业动态";
  }
}

function getCompanyArticlesList(pageNumber: any) {
  companyArticlesListApi(pageNumber).then((res) => {
    const formattedData = res.data.data.map((item: any) => {
      const coverUrl =
        item.attributes.cover.data == null
          ? ""
          : "https://pusi.mychery.com:5443" +
            item.attributes.cover.data.attributes.url;
      const title = item.attributes.title;
      const text = item.attributes.content;

      return {
        id: item.id,
        img: coverUrl,
        title: title,
        text: text,
      };
    });
    const pageMeta = res.data.meta;
    totalPage.value = pageMeta.pagination.pageCount;
    nowPage.value = pageMeta.pagination.page;
    dynamicData.value = formattedData;
  });
}
function partyListApi(pageNumber: any) {
  partyArticlesListApi(pageNumber).then((res) => {
    console.log(res);
    const formattedData = res.data.data.map((item: any) => {
      console.log(item.attributes.cover.data);
      const coverUrl =
        item.attributes.cover.data == null
          ? ""
          : "https://pusi.mychery.com:5443" +
            item.attributes.cover.data.attributes.url;
      console.log(coverUrl);
      const title = item.attributes.title;
      const text = item.attributes.content;
      return {
        id: item.id,
        img: coverUrl,
        title: title,
        text: text,
      };
    });
    console.log(formattedData, "formattedData");
    const pageMeta = res.data.meta;
    totalPage.value = pageMeta.pagination.pageCount;
    nowPage.value = pageMeta.pagination.page;
    dynamicData.value = formattedData;
  });
}
function getIndustryArticlesList(pageNumber: any) {
  industryArticlesListApi(pageNumber).then((res) => {
    const formattedData = res.data.data.map((item: any) => {
      const coverUrl =
        item.attributes.cover.data == null
          ? ""
          : "https://pusi.mychery.com:5443" +
            item.attributes.cover.data.attributes.url;
      const title = item.attributes.title;
      const text = item.attributes.content;
      return {
        id: item.id,
        img: coverUrl,
        title: title,
        text: text,
      };
    });
    const pageMeta = res.data.meta;
    totalPage.value = pageMeta.pagination.pageCount;
    nowPage.value = pageMeta.pagination.page;
    dynamicData.value = formattedData;
  });
}
handleCurrentChange(1);

function handleListClick(id: any) {
  console.log(id);
  let routeData = router.resolve({
    path: "/content",
    query: {
      id: id,
      category: category,
    },
  });
  window.open(routeData.href, "_blank");
}
// const activeIndex = ref("1");
// const menuItems = [
//   "业务介绍",
//   "关于普思",
//   "公司新闻",
//   "行业动态",
//   // "服务项目",
//   "党建活动",
//   "普思系统",
//   // "法规库",
// ];

const routerData = ref([
  { id: 1, title: "首页", path: "/", children: [] },
  { id: 2, title: "关于普思", path: "#about", children: [] },
  {
    id: 3,
    title: "业务介绍",
    path: "#business",
    children: [
      { pid: "3", id: "1", title: "法规库", path: "", children: [] },
      { pid: "3", id: "2", title: "参数收集", path: "", children: [] },
      { pid: "3", id: "3", title: "国内认证", path: "", children: [] },
      {
        pid: "3",
        id: "4",
        title: "国际认证",
        path: "",
        children: [
          { pid: "3-4", id: "1", title: "欧洲", path: "" },
          { pid: "3-4", id: "2", title: "巴西", path: "" },
          { pid: "3-4", id: "3", title: "台湾", path: "" },
        ],
      },
      { pid: "3", id: "5", title: "试验管理", path: "", children: [] },
      { pid: "3", id: "6", title: "创新发展", path: "", children: [] },
    ],
  },
  { id: 4, title: "公司新闻", path: "#news", children: [] },
  { id: 5, title: "行业动态", path: "#industryDynamics", children: [] },
  { id: 6, title: "党建活动", path: "#partyBuildingActivities", children: [] },
  {
    id: 7,
    title: "数字化管理平台",
    path: "#digitalManagmentPlatform",
    children: [],
  },
  {
    id: 8,
    title: "登录",
    path: "",
    children: [
      { pid: "8", id: "1", title: "内部登录", path: "", children: [] },
      { pid: "8", id: "2", title: "客户登录", path: "", children: [] },
      { pid: "8", id: "3", title: "合作伙伴", path: "", children: [] },
    ],
  },
]);
const activeIndex = ref("4");
const routerPath = useRouter();
const handleSelect = (key: string, keyPath: string[]) => {
  const obj = routerData.value.find(
    (item: any) =>
      Number(item.id) ===
      (keyPath.length === 1 ? Number(key) : Number(keyPath[0])),
  );
  if (obj) {
    if (Number(obj.id) === 1) {
      return routerPath.push(obj.path);
    }
    activeIndex.value = String(obj.id);
    // 跳锚链接 其他自行处理
    document.querySelector(obj.path)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};
const handleClick = (options: any) => {
  console.log(options);
  activeIndex.value = String(options.id);
  if (options.path) {
    // 跳锚链接 其他自行处理
    document.querySelector(options.path)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};

function navigateToRegulations() {
  // 使用 window.location.href 进行页面跳转
  let indexLink = "http://psbz.tjxsinfo.com:8002/bankuai02.html";
  window.open(indexLink, "_blank");
}
function handleItemClick() {
  // 使用 window.location.href 进行页面跳转
  let routeData = router.resolve({
    path: "/",
  });
  window.open(routeData.href, "_blank");
}
</script>

<template>
  <div class="home-center">
    <div class="home-center-header">
      <div class="container">
        <div class="navbar">
          <div class="navbar_logo">
            <img src="../assets/images/Logo.png" alt="" />
          </div>
          <el-menu
            ellipsis
            :default-active="activeIndex"
            class="navbar_list"
            mode="horizontal"
            @select="handleSelect"
            background-color="rgba(0, 140, 214, 1)"
            text-color="rgba(235, 235, 235, 1)"
            active-text-color="rgba(235, 235, 235, 1)"
          >
            <template v-for="item in routerData" :key="item.id">
              <template v-if="item.children.length === 0">
                <el-menu-item :index="String(item.id)">{{
                  item.title
                }}</el-menu-item>
              </template>
              <template v-else-if="item.children.length > 0">
                <el-sub-menu
                  :index="String(item.id)"
                  popper-class="navbar_popper navbar_popper_list"
                  @click.native="handleClick(item)"
                  :class="{ 'is-active': activeIndex === String(item.id) }"
                >
                  <template #title>
                    <span>{{ item.title }}</span>
                    <span class="icon">
                      <img src="../assets/images/a_b.png" alt="" />
                    </span>
                  </template>
                  <template v-for="child in item.children" :key="child.id">
                    <template v-if="child.children.length === 0">
                      <el-menu-item
                        :index="String(child.pid) + '-' + String(child.id)"
                        >{{ child.title }}</el-menu-item
                      >
                    </template>
                    <template v-else-if="child.children.length > 0">
                      <el-sub-menu
                        :index="String(child.pid) + '-' + String(child.id)"
                      >
                        <template #title>{{ child.title }}</template>
                        <template v-if="child.children">
                          <template v-for="sub in child.children" :key="sub.id">
                            <el-menu-item
                              :index="String(sub.pid) + '-' + String(sub.id)"
                              >{{ sub.title }}</el-menu-item
                            >
                          </template>
                        </template>
                        <template v-else-if="sub.children.length > 0">
                          <el-sub-menu
                            :index="String(sub.pid) + '-' + String(sub.id)"
                          >
                            <template #title>{{ sub.title }}</template>
                            <template v-if="sub.children">
                              <template
                                v-for="subs in sub.children"
                                :key="subs.id"
                              >
                                <el-menu-item
                                  :index="
                                    String(subs.pid) + '-' + String(subs.id)
                                  "
                                  >{{ subs.title }}</el-menu-item
                                >
                              </template>
                            </template>
                          </el-sub-menu>
                        </template>
                      </el-sub-menu>
                    </template>
                  </template>
                </el-sub-menu>
              </template>
            </template>
          </el-menu>
        </div>
      </div>
    </div>
    <!-- <el-row class="header">
      <img class="logo" src="../assets/Logo.png@2x.png" alt="普思标准" />
      <ul class="header-right">
        <li
          v-for="(item, index) in menuItems"
          :key="index"
          :class="{ selected: activeIndex === String(index + 1) }"
          @click="handleItemClick"
        >
          {{ item }}
        </li>
      </ul>
    </el-row> -->
    <div style="width: 100px; height: 100%">
      <img class="logo" src="../assets/listbanner.png" alt="普思标准" />
    </div>
    <div class="header-section">
      <!-- 以下为列表 -->
      <div
        style="
          padding-left: 210px;
          padding-right: 210px;
          padding-top: 70px;
          padding-bottom: 70px;
        "
      >
        <div style="font-size: 30px">{{ categoryText }}</div>
        <el-divider />
        <el-space
          direction="vertical"
          :size="30"
          :fill="true"
          style="width: 100%"
        >
          <el-card
            v-for="data in dynamicData"
            :key="data"
            class="box-card"
            style="width: 100%; border: 0px"
            shadow="never"
          >
            <el-row :gutter="0">
              <el-col :span="6">
                <img
                  :src="data.img"
                  style="width: 280px; height: 170px; border-radius: 20px"
              /></el-col>
              <el-col :span="18"
                ><div style="padding-left: 10px; padding-top: 15px">
                  <div
                    style="
                      margin-bottom: 20px;
                      font-size: 20px;
                      cursor: pointer;
                    "
                    v-on:click="handleListClick(data.id)"
                  >
                    {{ data.title }}
                  </div>
                  <div
                    style="
                      margin-bottom: 20px;
                      color: #9c9c9c;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <el-icon style="margin-right: 5px"><Clock /></el-icon>
                    <span style="padding-top: 2px">2024-03-12</span>
                  </div>
                  <div style="color: #9c9c9c" class="two-line-ellipsis">
                    {{ data.text }}
                  </div>
                </div></el-col
              >
            </el-row>
          </el-card>
        </el-space>
        <el-pagination
          background
          layout="prev, pager, next"
          :page-count="totalPage"
          style="margin-top: 50px"
          :current-page="nowPage"
          :hide-on-single-page="false"
          @current-change="handleCurrentChange"
        />
      </div>

      <!-- 以下为页脚 -->
      <div>
        <div class="layout-1 other">
          <div class="other-bottom">
            <div class="box">
              <div class="other-bottom-box">
                <p class="title">合规举报渠道</p>
                <p class="text">合规电话</p>
                <p class="text">0553-7526147</p>
                <p class="text">合规邮箱</p>
                <p class="text">hegui@mychery.com</p>
              </div>
              <!-- <div class="other-bottom-box">
                      <p class="title">我们的服务</p>
                      <p class="text">国内认证</p>
                      <p class="text">国际认证</p>
                      <p class="text">道路试验</p>
                      <p class="text">创新发展</p>
                    </div> -->
              <div class="other-bottom-box">
                <p class="title">普思数字化管理平台</p>
                <p class="text">产品认证管理系统</p>
                <p class="text">标准法规库</p>
                <p class="text">数字化道路实验室</p>
                <p class="text">业财一体化平台</p>
                <p class="text">数据集成系统</p>
                <!-- <p class="text">系统一</p>
                      <p class="text">系统一</p> -->
              </div>
              <div class="other-bottom-box">
                <p class="title">联系我们</p>
                <p class="text my-box">
                  公司地址：中国(安徽)自由贸易试验区芜湖片区银湖北路38号留学生楼D501室
                </p>
                <p class="text my-box">
                  公司电话：0553-5636736 工作日09:00-18:00
                </p>
                <p class="text my-box">邮编：241006</p>
                <p class="text">&nbsp;</p>
              </div>
              <!-- <div class="other-bottom-box wx">
                      <p class="text">&nbsp;</p>
                      <div class="text text-box"></div>
                      <p class="text text-box-bottom">资质证书</p>
                    </div> -->
            </div>
          </div>
          <div class="footer">
            <div>©2023 普思标准所有</div>
            <div class="box"></div>
            <div>京ICP备5683325568号</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import url("./home/header.min.css");
.home-center {
  &-header {
    width: 100%;
    // height: 78px;
    // background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .container {
      height: 100%;
      width: 1400px;
      position: relative;
    }
  }
}
:deep(
    .el-carousel
      .el-carousel__indicators--right
      .el-carousel__indicator--vertical
      button
  ) {
  width: 16px;
  height: 16px;
  opacity: 0.4;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  margin-bottom: 16px;
}

:deep(.el-carousel .el-carousel__indicators--right .is-active button) {
  background: rgba(0, 140, 214, 1);
  border: none;
  border-radius: 50%;
  opacity: 0.5;
}
.dynamic {
  .item {
    .el-carousel__item .el-carousel__mask {
      background: transparent !important;
    }
    :deep(.el-carousel__container .el-carousel__item .el-carousel__mask) {
      background: #fff !important;
      border-radius: 32px;
      opacity: 0;
    }
    :deep(.el-carousel .el-carousel__container .el-carousel__arrow) {
      width: 70px;
      height: 70px;
      background-color: rgba(0, 0, 0, 1);
    }
    :deep(.el-carousel .el-carousel__container .el-carousel__arrow .el-icon) {
      font-size: 28px;
      color: #fff;
    }
    :deep(.el-carousel__container .is-active) {
      background-color: rgba(0, 140, 214, 1) !important;
      border-radius: 32px;
    }

    :deep(.el-carousel__container .is-active .dynamic-box) {
      .top {
        color: rgba(255, 255, 255, 1) !important;
        padding: 0 12px;
      }
      .bottom {
        color: rgba(255, 255, 255, 1) !important;
        padding: 0 12px;
      }
    }
    :deep(.el-carousel__container .is-in-stage) {
      background-color: #fff;
      border-radius: 32px;
    }
    .el-carousel__item .dynamic-box {
      height: 100%;
      border-radius: 32px;
      img {
        width: 100%;
        height: 360px;
      }
      .top {
        /** 文本1 */
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 34.75px;
        color: rgba(0, 0, 0, 1);
        padding: 0 12px;
        margin: 17px 0 0 0;
      }
      .bottom {
        /** 文本1 */
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(153, 153, 153, 1);
        padding: 0 12px;
      }
    }
  }
}
</style>
