import service from "../utils/request";

export const companyArticlesApi = () => {
  return service.get(
    "/api/company-articles?sort=createdAt:desc&pagination[page]=1&pagination[pageSize]=3&populate=cover"
  );
};

export const partyArticlesApi = () => {
  return service.get(
    "/api/party-articles?sort=createdAt:desc&pagination[page]=1&pagination[pageSize]=3&populate=cover"
  );
};

export const industryArticlesApi = () => {
  return service.get(
    "/api/industry-articles?sort=createdAt:desc&pagination[page]=1&pagination[pageSize]=3&populate=cover"
  );
};

export const partyArticlesListApi = (pageNumber: any) => {
  if (pageNumber == false) {
    pageNumber = 1;
  }
  return service.get(
    `/api/party-articles?sort=createdAt:desc&pagination[page]=${pageNumber}&pagination[pageSize]=5&populate=cover`
  );
};

export const companyArticlesListApi = (pageNumber: any) => {
  if (pageNumber == false) {
    pageNumber = 1;
  }
  return service.get(
    `/api/company-articles?sort=createdAt:desc&pagination[page]=${pageNumber}&pagination[pageSize]=5&populate=cover`
  );
};

export const industryArticlesListApi = (pageNumber: any) => {
  if (pageNumber == false) {
    pageNumber = 1;
  }
  return service.get(
    `/api/industry-articles?sort=createdAt:desc&pagination[page]=${pageNumber}&pagination[pageSize]=5&populate=cover`
  );
};

export const contentApi = (category: any, id: any) => {
  if (category == "party") {
    category = "party-articles";
  } else if (category == "company") {
    category = "company-articles";
  } else if (category == "industry") {
    category = "industry-articles";
  }
  return service.get(`/api/${category}/${id}?populate=cover`);
};
